import React from 'react'
import { useEffect, useState } from "react";
import './style/paymentSuccess.css'
import { service } from './service';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';

function PaymentSuccess() {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate()
  useEffect(() => {
    const sessionId = urlParams.get("session_id");
    if (sessionId) {
      fetchStripeDecode(sessionId)
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 500);
      navigate('/failed');
    }
  }, []);

  const fetchStripeDecode = async (sessionId) => {

    try {
      const stripeDecodeResponse = await service.stripeDecode(sessionId);

      if (stripeDecodeResponse?.success === true) {

        const subscription = stripeDecodeResponse?.data;
        setSubscriptions(subscription)
        let modeOfPayment = 'stripe';

        setTimeout(() => {
          setIsLoading(false)
        }, 500);
      } else {
        setTimeout(() => {
          setIsLoading(false)
        }, 500);
        navigate('/failed');
      }
    } catch (error) {
      console.error('Error fetching Stripe decode:', error);
      setTimeout(() => {
        setIsLoading(false)
      }, 500);
      navigate('/failed');
    }
  };

  const handleClick = async () => {
    const pubInfoJSON = localStorage.getItem('pubInfo');
    const pubInfo = JSON.parse(pubInfoJSON);
    const mappedSubscription = {
      sub_id: subscriptions.id,
      price: subscriptions.amount_total,
      valid_to: subscriptions.expires_at,
      valid_from: subscriptions.created,
      receipt_id: subscriptions.subscription,
      currency: subscriptions.currency
    };

    const mappedPubInfo = {
      pubName: pubInfo.name,
      contact: pubInfo.contact,
      user_name: pubInfo.email,
      password: pubInfo.password,
      email: pubInfo.email,
      service: pubInfo.service,
      website: pubInfo.website,
      timezone: pubInfo.timezone,
      company_name: pubInfo.company_name
    };

    const mergedData = {
      ...mappedSubscription,
      ...mappedPubInfo
    };

    navigate("/template", { state: { mergedData } });
  }
  return (
    <div style={{ background: "#000" }}>
      {
        isLoading ? <Loader /> :
          <div className="success">
            <div className="successContainer">
              <div className="checkIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="92"
                  height="92"
                  fill="#fff"
                  className="bi bi-check2"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                </svg>
              </div>
              <h5>Success</h5>
              <p>Your payment has successfully completed</p>
              <button onClick={() => handleClick()}>Countinue</button>
            </div>
          </div>
      }

    </div>

  )
}

export default PaymentSuccess
