import React, { Fragment, useState,useEffect } from 'react'

//react bootstrap
// import { Col, Container, Form, Row } from 'react-bootstrap'
import { Container, Row, Col, Button } from 'react-bootstrap';
//react-router-dom
// import { Link } from 'react-router-dom'

//components
import {  useParams } from "react-router-dom";
import Logo from '../../assets/images/01.webp'
import gizmott from '../../assets/images/falcon.png'
import verify from '../../assets/images/accept.png'
import './style/verify.css'
import { Link } from 'react-router-dom'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import { service } from './service';
import Loader from '../../components/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Verification = (() => {
    const { token } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await service.verifyEmail(token);
                
                if (response.success === true) {
                    console.log('Token validated');
                    setIsLoading(false)
                } else {
                    // setIsLoading(false)
                    toast.error(response.message, {
                        position: "bottom-center",
                      });
                    // setIsLoading(true)
                }
            } catch (error) {
                console.error('Error validating token:', error);
            }
        };
    
        validateToken();
    }, [token]);
    return (
        <Fragment>
            <main className='main-content'>
                {
                    isLoading ? <Loader/>:
                    <div className='vh-100' style={{ backgroundImage: `url(${Logo})`, backgroundSize: 'cover', backgroundRepeat: "no-repeat", position: 'relative', minHeight: '55rem' }}>
                    <Container>
                        <Row className='justify-content-center align-items-center height-self-center vh-100'>
                            <Col lg="5" md="12" className='align-self-center'>
                                <div className='user-login-card bg-body-card'>
                                    <div className='text-center'>
                                      
                                    <img
                                    src={gizmott}
                                    />
                                    </div>
                                    <Container fluid className="verification-container">
      <Row className="justify-content-center align-items-center">
        <Col lg="6" md="8" sm="10" xs="12" className="text-center">
          <img src={verify}  alt="Verification Success" className="verification-image" />
          <h2 className="verification-heading">Email Verified Successfully</h2>
          <p className="verification-text">Congratulations! Your email has been successfully verified.</p>
          <Button variant="primary" href="/" className="verification-button">Continue</Button>
        </Col>
      </Row>
    </Container>
   
                                    </div>
                                 </Col>
                        </Row>
                    </Container>
                </div>
                }
         
            </main>
        </Fragment>
    )
})

// Register.displayName = "Register"
export default Verification