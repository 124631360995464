import axios from 'axios';

const getTemplateList = async () => {    
    try {
        const url = `${process.env.REACT_APP_API_URL}/templates`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        // Handle the error here, you might want to log it or return an error object
        console.error('Error fetching subscription plans:', error);
        throw error; // Rethrow the error so the caller can handle it
    }
};
const register = (requestBody) => {
    // let requestBody ={
    //     data
    // }

    let url = `${process.env.REACT_APP_API_URL}/register`
    return axios.post(url, requestBody).then(response => {
        return response.data
    }).catch(e => { return { data: false } })
}
export const service = {
    getTemplateList,
    register
};