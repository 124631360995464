import { Fragment, memo } from "react";

//react bootstrap
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";

//function
import './style/pricingWidget.css'
import logo from "../../assets/images/01.webp"
//img
// const imagePath = generateImgPath("../../assets/images/pages/01.webp");

const PricingWidget = memo((props) => {
  return (
    <Fragment>
      <div
        className="iq-breadcrumb"
        style={{ backgroundImage: `url(${logo})` }}
      >
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <nav className="text-center">
                <h2 className="title text-capitalize" style={{color:"#fff"}}>{props.title}</h2>
                <Breadcrumb
                  className="main-bg"
                  listProps={{
                    className: "text-center justify-content-center",
                  }}
                >
                  <Breadcrumb.Item className="home-breadcrumb-item">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                </Breadcrumb>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

PricingWidget.displayName = "PricingWidget";
export default PricingWidget;
