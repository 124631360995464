import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { service } from "./service";
import Loader from "../Loader/Loader";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import './style/pricing.css';
import PricingWidget from '../widgets/PricingWidget'
const Pricing = (() => {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [pubid, setPubid] = useState('');
  const [radioValue, setRadioValue] = useState('1');
  const radios = [
    { name: 'Monthly', value: '1' },
    { name: 'Yearly', value: '2' },
  ];
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.pubid) {
      setPubid(location?.state?.pubid);
    }
  }, [location?.state]);

  useEffect(() => {
    const subscriptionList = async () => {
      try {
        const response = await service.subscriptionsPlans();

        if (response.success === true) {
          setIsLoading(false);
          setSubscriptions(response.data);
        } else {
          // Handle error
        }
      } catch (error) {
        console.error('Error validating token:', error);
      }
    };

    subscriptionList();
  }, []);

  const filteredSubscriptions = subscriptions.filter(subscription => {
    return subscription.type === (radioValue === '1' ? 'Monthly' : 'Yearly');
  });

  const handleSelectSubscription = async (subscription, pubid) => {
    const response = await service.stripeAcess(subscription, pubid);
    if (response.success === true) {
      window.open(response.url, "_self");
    } else {
      // Handle error
    }
  };

  return (
    <Fragment>
      <PricingWidget title="Pricing Plan"/>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="section-padding">
          <Container>
            <Row className="toggleStyle">
              <ButtonGroup style={{width:'50%'}}>
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                    style={{
                      borderRadius: '20px', 
                      padding: '10px 20px',
                      margin: '5px', 
                      fontWeight: 'bold',
                      fontSize: '16px', 
                      background: 'transparent', 
                      color: radioValue === radio.value ? 'white' : 'grey', 
                      border: `2px solid ${radioValue === radio.value ? 'green' : 'red'}`, 
                      boxShadow: 'none',
                      fontFamily:'Roboto, sans-serif, Liberation Mono, Courier New, monospace'
                    }}
                  >
                    {radio.name}
                    
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Row>
            <Row>
              {filteredSubscriptions.map((subscription, index) => (
                <Col key={index} lg="4" md="6" className="mb-3 mb-lg-0">
                  <div className="pricing-plan-wrapper">
                    {subscription.name === "Growth" && (
                      <div className="pricing-plan-discount p-2 text-center">
                        <span className="text-white">Save 20%</span>
                      </div>
                    )}
                    <div className="pricing-plan-header">
                      <h4 className="plan-name text-capitalize">{subscription.name}</h4>
                      {subscription.price && (
                        <Fragment>
                          <span className="main-price text">${subscription.price}</span>
                          <span className="font-size-18 custom-font">{subscription.month}</span>
                        </Fragment>
                      )}
                    </div>
                    <div className="pricing-details">
                      <div className="pricing-plan-description">
                        <ul className="list-inline p-0">
                          {subscription.description.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              {item.freeFlag ? (
                                <i className="fas fa-check text-danger"></i>
                              ) : (
                                <i className="fas fa-times text-white"></i>
                              )}
                              <span className="font-size-18 fw-500 custom-font">{item.text}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="pricing-plan-footer">
                        <div className="iq-button">
                          <button to="#" className="btn text-uppercase position-relative" onClick={() => handleSelectSubscription(subscription, pubid)}>
                            <span className="button-text">select {subscription.name.toLowerCase()}</span>
                        
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      )}
    </Fragment>
  );
});

export default Pricing;
