
import axios from 'axios';
const verifyEmail = async (token) => {
    
    const data = {
        token
    };

    return axios.post(`${process.env.REACT_APP_API_URL}/emailVerify`, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error", error.response);
            return error.response;
        });
};
const passwordUpdate = async (data) => {

    return axios.post(`${process.env.REACT_APP_API_URL}/password/update`, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error", error.response);
            return error.response;
        });
};


export const service = {
    verifyEmail,
    passwordUpdate
};