import React from 'react';
import Layout from './layouts/Layout';
function App() {

  return (
<>
<Layout/>
</>
  );
}

export default App;
