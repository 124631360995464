import React ,{useEffect}from 'react';
import { BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Register from '../auth/Register/Register';
import Verification from '../auth/Verify/Verification';
import Pricing from '../components/Pricing/Pricing';
import PaymentSuccess from '../components/PaymentSuccess/PaymentSuccess';
import PaymentFailed from '../components/PaymentFailed/PaymetFailed';
import Template from '../components/Template/Template';

function Layout() {
  useEffect(() => {
    const pubInfoJSON = localStorage.getItem('pubInfo');
    const currentPath = window.location.pathname;
    
    console.log(currentPath != '/' && currentPath != '/success'  && !pubInfoJSON,'current');
    if (currentPath != '/' && currentPath != '/success'  && !pubInfoJSON) {
      window.location.href ='/';
    }
  }, []);
  return (
    <div>
      <Router>
                <Routes>
                    <Route path="/" exact element={<Register />} />
                    <Route path="/verify/:token" element={<Verification />} />
                    <Route path="/pricing-plans" element={<Pricing />} />
                    <Route path="/success" element={<PaymentSuccess />} />
                    <Route path="/failed" element={<PaymentFailed />} />
                    <Route path="/template" element={<Template />} />
                </Routes>
       </Router>
  </div>
  );
}

export default Layout;
