import axios from "axios";
const stripeDecode = async (sessionId,pubid) => {    
    try {
        const requestData = {
            sessionId: sessionId,
          };
        const url = `${process.env.REACT_APP_API_URL}/stripe/details`;
        const response = await axios.get(url, { params: requestData });
        
        return response.data;
    } catch (error) {
        // Handle the error here, you might want to log it or return an error object
        console.error('Error fetching subscription plans:', error);
        throw error; // Rethrow the error so the caller can handle it
    }
};

export const service = {
    stripeDecode
};