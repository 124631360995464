import React, { useState } from 'react';
import './style/paymentFailed.css'
import { useNavigate } from 'react-router-dom';
const PaymentFailed = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/');

  }
 
  return (
    <div style={{background:"#000"}}>
    <div className='failed'>
        <div className="failedContainer">

        <div className="crossIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#fff" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>
        </div>
        <h5>Error!</h5>
        <p>Your payment has been failed.<br/>Please try again..</p>
        <button onClick={()=>handleClick()}>OK</button>
        </div>
    </div>
    </div>
  )
}

export default PaymentFailed