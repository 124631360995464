import React from 'react'
import './style/Loader.css'
function Loader() {
  return (

    <div className="loadingContainer">
      <div className="loading">
        <div className="loadingOverlay"></div>
        <span class="loader"></span>

      </div>
    </div>

  )
}

export default Loader
