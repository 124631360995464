import axios from 'axios';

const subscriptionsPlans = async () => {    
    try {
        const url = `${process.env.REACT_APP_API_URL}/subscriptions`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        // Handle the error here, you might want to log it or return an error object
        console.error('Error fetching subscription plans:', error);
        throw error; // Rethrow the error so the caller can handle it
    }
};
const stripeAcess = async (subscriptions,pubid) => {    
    try {
        const requestData = {
            sub_id: subscriptions.sub_id,
            price: subscriptions.price
          };
        const url = `${process.env.REACT_APP_API_URL}/stripe/access`;
        const response = await axios.get(url, { params: requestData });
        return response.data;
    } catch (error) {
        // Handle the error here, you might want to log it or return an error object
        console.error('Error fetching subscription plans:', error);
        throw error; // Rethrow the error so the caller can handle it
    }
};

export const service = {
    subscriptionsPlans,
    stripeAcess
};