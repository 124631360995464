import React, { Fragment, useState, useEffect } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { service } from './service'
import { useNavigate, Outlet } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/images/01.webp'
import gizmott from '../../assets/images/falcon.png'
import './style/register.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loader from '../../components/Loader/Loader';
const Register = (() => {
  const [values, setValues] = useState({
  });
  const [timezoneList, setTimezoneList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTimezone = async () => {
      try {
        const response = await service.getTimeZone();
        if (response.success === true) {
          let timezoneOptions = response.data.map(item => ({
            label: item.name,
            value: item.value
          }));
          timezoneOptions.unshift({ label: 'Choose Time Zone', value: '' });
          setIsLoading(false)
          setTimezoneList(timezoneOptions);
        } else {
          // setIsLoading(false)
        }
      } catch (error) {
        console.error('Error validating token:', error);
      }
    };


    fetchTimezone()
  }, []);
  const handleChange = (e) => {

    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };


  const onDialCodeChangeHandler = (phone_number) => {
    setValues({
      ...values,
      phone: phone_number,
    });
  }
  const validateForm = () => {
    const newErrors = {};
    let formIsValid = true
    if (!values.name) {
      newErrors.name = 'Name is required';
      formIsValid = false
    }
    if (!values.email) {
      newErrors.email = 'Email is required';
      formIsValid = false
    } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
      newErrors.email = 'Invalid email format';
      formIsValid = false
    }
    if (!values.phone) {
      newErrors.phone = 'Phone is required';
      formIsValid = false
    }
    if (!values.timezone) {
      newErrors.timezone = 'Timezone is required';
      formIsValid = false
    }
    setErrors(newErrors);
    return formIsValid
  };
  const handleSubmit = (e) => {

    e.preventDefault();
    if (validateForm()) {
      service.emailExists(values).then(
        (response) => {
          if (response.success === true) {
            if (response.data) {
              setErrors({ ...errors, email: response.message });
            } else {
              toast.success(response.message, {
                position: "bottom-center",
              });


              const pubInfo = {
                name: values.name,
                contact: values.phone,
                email: values.email,
                timezone: values.timezone,
                website: values.website,
                service: values.service,
                company_name: values.companyName
              };

              const pubInfoJSON = JSON.stringify(pubInfo);

              localStorage.setItem('pubInfo', pubInfoJSON);

              navigate("/pricing-plans", { state: { pubid: response.pubid } });
            }

          } else {
            return false;
          }
        },
        (error) => {
          return false;
        }
      );
    } else {

    }
  };
  return (
    <Fragment>
      {
        isLoading ? <Loader /> :
          <main className='main-content'>
            <div className='vh-100' style={{ backgroundImage: `url(${Logo})`, backgroundSize: 'cover', backgroundRepeat: "no-repeat", position: 'relative', minHeight: '55rem' }}>
              <Container>
                <Row className='justify-content-center align-items-center height-self-center vh-100'>
                  <Col lg="5" md="12" className='align-self-center'>
                    <div className='user-login-card bg-body-card'>
                      <div className='text-center'>

                        <img
                          src={gizmott}
                        />
                      </div>
                      <Form action='post' onSubmit={handleSubmit}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='text-white fw-500 mb-2 custom-font' >Name *</Form.Label>
                          <Form.Control type='text' className='rounded-0' name="name" onChange={handleChange} style={{ color: 'white', outline: 'none', }}

                            onFocus={(e) => {
                              e.target.style.outline = `#e50914 solid 2px`;
                            }}
                            onBlur={(e) => {
                              e.target.style.outline = 'none';
                            }}

                          />
                          {errors.name && <div className="text-danger">{errors.name}</div>}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                          <Form.Label className='text-white fw-500 mb-2 custom-font'>Email Address *</Form.Label>
                          <Form.Control type='text' className='rounded-0' name="email" onChange={handleChange} style={{ color: 'white' }}
                            onFocus={(e) => {
                              e.target.style.outline = `#e50914 solid 2px`;
                            }}
                            onBlur={(e) => {
                              e.target.style.outline = 'none';
                            }}

                          />
                          {errors.email && <div className="text-danger">{errors.email}</div>}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                          <Form.Label className='text-white fw-500 mb-2 custom-font'>Time Zone *</Form.Label>
                          <Form.Select aria-label="Default select example" className='rounded-0' name="timezone" onChange={handleChange} style={{ color: 'white' }}
                            onFocus={(e) => {
                              e.target.style.outline = `#e50914 solid 2px`;
                            }}
                            onBlur={(e) => {
                              e.target.style.outline = 'none';
                            }}

                          >
                            {timezoneList.map(timezone => (
                              <option key={timezone.id} value={timezone.value}>{timezone.label}</option>
                            ))}
                          </Form.Select>
                          {errors.timezone && <div className="text-danger">{errors.timezone}</div>}
                        </Form.Group>

                        <Form.Group className='mb-3'>
                          <Form.Label className='text-white fw-500 mb-2 custom-font'>Phone *</Form.Label>
                          <PhoneInput

                            enableSearch={true}
                            value={values.phone}
                            dropdownStyle={{ height: '200px' }}
                            country={'us'}
                            inputStyle={{
                              background: '#141314',
                              border: 'none',
                              width: '100%',
                              color: "#fff"
                            }}
                            onChange={onDialCodeChangeHandler}

                            onFocus={(e) => {
                              e.target.style.outline = `#e50914 solid 2px`;
                            }}
                            onBlur={(e) => {
                              e.target.style.outline = 'none';
                            }}

                          />

                          {errors.phone && <div className="text-danger">{errors.phone}</div>}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                          <Form.Label className='text-white fw-500 mb-2 custom-font'>Company Name(Optional)</Form.Label>
                          <Form.Control type='text' className='rounded-0' name="companyName" onChange={handleChange} style={{ color: 'white' }}

                            onFocus={(e) => {

                              e.target.style.outline = `#e50914 solid 2px`;
                            }}
                            onBlur={(e) => {

                              e.target.style.outline = 'none';
                            }}
                          />
                        </Form.Group>


                        <Form.Group className='mb-3'>
                          <Form.Label className='text-white fw-500 mb-2 custom-font'>Website(Optional)</Form.Label>
                          <Form.Control type='text' className='rounded-0' name="website" onChange={handleChange} style={{ color: 'white' }}

                            onFocus={(e) => {
                              e.target.style.outline = `#e50914 solid 2px`;
                            }}
                            onBlur={(e) => {
                              e.target.style.outline = 'none';
                            }}
                          />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                          <Form.Label className='text-white fw-500 mb-2 custom-font'>Service Name(Optional)</Form.Label>
                          <Form.Control type='text' className='rounded-0' name="service" onChange={handleChange} style={{ color: 'white' }}

                            onFocus={(e) => {

                              e.target.style.outline = `#e50914 solid 2px`;
                            }}
                            onBlur={(e) => {

                              e.target.style.outline = 'none';
                            }}
                          />
                        </Form.Group>



                        <div className="full-button" style={{ background: "#e50914" }}>
                          <div className="iq-button">

                            <button type="submit" className="btn text-uppercase position-relative" onClick={handleSubmit}>
                              Next
                            </button>

                          </div>
                        </div>
                      </Form>


                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </main>
      }

    </Fragment>
  )
})


export default Register