import axios from 'axios';
const emailExists = (values) => {
    let requestBody ={
        email:values.email
    }
    
    let url = `${process.env.REACT_APP_API_URL}/emailExists`
    return axios.post(url, requestBody).then(response => {
        return response.data
    }).catch(e => { return { data: false } })
}
const getTimeZone = async () => {
    
    let url = `${process.env.REACT_APP_TIMEZONE_API_URL}/timezone/list`
    try {
        
        const response = await axios.get(url);
        
        return response.data;
    } catch (e) {
        return { data: false };
    }
}

export const service = {
    emailExists,
    getTimeZone
};