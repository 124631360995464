import React, { Fragment, useEffect, useState } from 'react'
import PricingWidget from '../widgets/PricingWidget'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import { Container } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { service } from './service';
import Loader from '../Loader/Loader';
import './style/template.css'
function Template() {
  const location = useLocation();
  const [templateList, setTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pubData, setPubData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.mergedData) {
      setPubData(location?.state?.mergedData);
    }
  }, [location?.state]);
  useEffect(() => {
    const templateDetails = async () => {
      try {
        const response = await service.getTemplateList();

        if (response.success === true) {
          setIsLoading(false);
          setTemplateList(response.data);
        } else {
          // Handle error
        }
      } catch (error) {
        console.error('Error validating token:', error);
      }
    };

    templateDetails();
  }, []);
  const handleViewTemplate = (url) => {
    window.open(url, '_blank');
  };

  const handleChooseTemplate = (template) => {
    setSelectedTemplate(template.id);
  };
  const handleFinish = async () => {
    try {

      const mergedData = { ...pubData, template_id: selectedTemplate };
      const registerRes = await service.register(mergedData);

      if (registerRes.success === true) {

        localStorage.removeItem('pubInfo');
        toast.success(registerRes.message)
      } else {
        toast.success(registerRes.message)
      }
    } catch (error) {
      console.error('Error registering:', error);
      toast.error('Something went wrong, please try again later.')
    }
  };
  return (
    <Fragment>
      {
        isLoading ? <Loader /> :

          <>
            <PricingWidget title="Choose Template" />
            <div className="section-padding">
              <Container>
                <div className="template-list">
                  {templateList.map(template => (
                    <div key={template.id} className={`template-item ${selectedTemplate === template.id ? 'selected' : ''}`} onClick={() => handleChooseTemplate(template)}>
                      <div className="template-checkbox">
                        {selectedTemplate === template.id && <i className="fas fa-check-circle"></i>}
                      </div>
                      <img src={template.image_url} alt="Template" className="img-fluid" />
                      <div className="template-details">
                        <span className="template-title">{template.name}</span>
                        <div className={`view-btn ${selectedTemplate === template.id ? 'selected' : ''}`} onClick={() => handleViewTemplate(template.url)}>

                          Live Preview
                        </div>
                      </div>
                      {selectedTemplate === template.id && (
                        <div className="verification-badge">
                          <i className="fas fa-check"></i>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className='button-container'>
                  <div className="full-button" style={{ background: "#e50914", width: '30%' }}>
                    <div className="iq-button">

                      <button type="submit" className="btn text-uppercase position-relative" onClick={handleFinish} >
                        Finish
                      </button>

                    </div>
                  </div>
                </div>

              </Container>

            </div></>

      }

    </Fragment>
  )
}

export default Template
